<template>
    <div>
        <div class="flex w-full justify-end my-3">
            <vs-button icon="add" @click="addService">{{ $t("Add")}}</vs-button>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            <div v-for="service in services" :key="service.id">
                <vs-card fixed-height>
                    <div slot="media">
                        <img :src="imagesURL+'/'+service.main_image" class="object-cover h-48 rounded-t-lg" @load="onImgLoad" v-show="imgsLoaded">
                        <div v-show="!imgsLoaded" class="object-cover h-48 rounded-t-lg">
                            <div class="flex items-center justify-center w-full h-48 rounded sm:w-96">
                                <svg class="w-10 h-10" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div slot="extra-content">
                        <!-- EDIT & ACTION BUTTONS -->
                        <div class="container">
                            <div class="vx-row justify-end px-2 pt-2">
                                <div class="flex">
                                    <vs-dropdown class="mx-1" vs-trigger-click>
                                        <vs-button color="dark" type="border" icon="more_horiz"></vs-button>
                                        <vs-dropdown-menu>
                                            <vs-dropdown-item>
                                                <vs-button class="mt-2 px-2" @click="duplicateService(service)" icon-pack="FA5" icon="far fa-copy" type="flat" color="dark">{{$t('home.serviceForm.duplicateService')}}</vs-button>
                                            </vs-dropdown-item>
                                            <vs-dropdown-item>
                                                <vs-button class="mt-2 px-2" @click="deleteServicePromptActive = true; selectedService = service" icon-pack="FA5" icon="far fa-trash" type="flat" color="danger">{{$t('home.serviceForm.deleteService')}}</vs-button>
                                            </vs-dropdown-item>
                                        </vs-dropdown-menu>
                                    </vs-dropdown>
                                    <vs-button color="primary" type="border" icon="edit" @click="editService(service)"></vs-button>
                                </div>
                            </div>

                            <hr>
                        </div>
                        <!--// EDIT & ACTION BUTTONS //-->



                        <!-- SERVICE DATA (NAME - DESCRIPTION.. ETC) -->
                        <div class="">
                            <div class="vx-row px-2 py-5">
                                <div class="vx-col w-2/3">
                                    <span>{{service.name}}</span> <br /> <small class="font-medium">{{service.description}}</small>
                                </div>
                                <div class="vx-col w-1/3">
                                    <small class="font-medium">{{$t('home.editBookingOptionForm.thePrice')}}</small> 
                                    <br />
                                    <span v-if="service.pricing_type == 'fixed'">{{service.final_price.toLocaleString()}} {{$t('SAR')}}</span>
                                    <span v-else>
                                        <span v-if="service.first_input_price > 0">{{Math.round((service.first_input_price * 1.15)).toLocaleString()}} {{$t('SAR')}} <small class="font-medium">/ {{$t('person')}}</small></span>
                                        <br v-if="service.second_input_price > 0" />
                                        <span v-if="service.second_input_price > 0">{{Math.round((service.second_input_price * 1.15)).toLocaleString()}} {{$t('SAR')}} <small class="font-medium">/ {{$t('hour')}}</small></span>
                                    </span>
                                    <br /> 
                                    <small class="font-medium">({{$t('vatIncluded')}})</small> 
                                </div>
                            </div>
                        </div>
                        <!--// SERVICE DATA (NAME - DESCRIPTION.. ETC) //-->
                    </div>
                </vs-card>
            </div>
        </div>
        <vs-pagination class="mt-5" :total="Math.ceil(tableConfig.totalPages)" v-model="currentx" @input="handleChangePage"></vs-pagination>
        

        <!-- ADD - EDIT - DUPLICATE service form -->
        <service-form-popup :isServiceFormActive.sync="isServiceFormActive" :serviceProp="selectedService" :actionType="actionType"/>
    
        <!-- DELETE service prompt -->
        <vs-prompt :title="$t('home.serviceForm.delete.promptHeader')" color="danger" @accept="deleteBookingOption" @cancel="selectedService = null" @close="selectedService = null" :accept-text="$t('home.serviceForm.delete.accept')" :cancel-text="$t('home.serviceForm.delete.cancel')" :active.sync="deleteServicePromptActive" v-if="selectedService">
          {{$t('home.serviceForm.delete.message', { serviceName: selectedService.name })}}
        </vs-prompt>
    </div>
</template>
    
<script>
import axios from '@/axios'
import AddOnForm   from '/src/components/AddOnForm.vue'
import ServiceFormPopup from '@/components/Services/ServiceFormPopup.vue'
export default {
    data () {
        return {      
            currentx: 1,
            tableConfig: {
                totalPages: 0,
                perPage: 12,
                from: 1
            },
            isServiceFormActive: false,
            selectedService: null,


            serviceActive: false,
            editBookingOptionPromptActive: false,
            deleteServicePromptActive: false,
            currentService : {},
            isEdit: false,
            actionType: null,

            imgsLoaded: false
        }
      },
      components: {
          AddOnForm,
          ServiceFormPopup
      },
      computed: {
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        },
        imagesURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
        services () {
            return this.$store.state.services.services
        },
      },
      created () {
        this.getServices();
      },
      methods: {
        async getServices(page=1) {
            let query = {
                page: page,
                per_page: this.tableConfig.perPage
            }

            this.$store.dispatch('loader/loaderOn')
                
            const response = await this.$store.dispatch('services/getServices', {venueId: this.currentVenueId, query})

            this.$store.dispatch('loader/loaderOff')

            this.tableConfig.totalPages = response.data.meta.last_page
            this.tableConfig.from = response.data.meta.from
        },
        handleChangePage(data) {
            this.getServices(data);
        },
        editService(service) {
            this.actionType = 'editService';
            this.selectedService = service;
            this.isServiceFormActive = true;
        },
        addService() {
            this.selectedService = {
                pricing_type: 'fixed',
                percentage_tax: 1,
                custom_prices: [],
                images: []
            };
            this.actionType = 'addService';
            this.isServiceFormActive = true;
            // this.isEdit = false;
        },
        duplicateService(service) {
            this.actionType = 'duplicateService';
            this.isServiceFormActive = true;
            this.selectedService = service;
        },
        async deleteBookingOption() {
            this.$store.dispatch('loader/loaderOn')
            let data = {
                service: this.selectedService,
            }

            const response = await this.$store.dispatch('services/deleteService', {venueId: this.currentVenueId, data});            

            this.$store.dispatch('loader/loaderOff')
            if(response.status == 200) {
                this.closePopup();
                this.$vs.notify({
                    color:'success',
                    text: response.data.message
                })
            } else {
                let errors = response.data.errors;
                Object.keys(errors).forEach(key => {
                    this.$vs.notify({
                        color:'danger',
                        icon: 'warning',
                        text: errors[key][0]
                    })
                });
            }
        },
        onImgLoad () {
            this.imgsLoaded = true
        },
      }
}
</script>
    
<style>
hr{
    height: 0.5px;
    border-color: rgb(255, 255, 255) !important;
    opacity: 0.5;
    margin-top: 10px;
}
</style>