<template>
    <div v-if="currentService != null">

        <vs-popup class="holamundo"  :title="!isEdit ? $t('servicesForm.addService') : $t('servicesForm.editService') +' | '+ currentService.name" :active.sync="isActive" @close="closePopup">
        <div class="container w-full">
            <div class="flex flex-wrap">
                <h4 class="my-3 opacity-75 w-full" >{{$t('servicesForm.serviceImages')}}</h4>
                <div class="vx-col  w-full lg:w-1/2 md:mb-2 pl-2 mt-2">
                    <vue-upload-multiple-image
                        idUpload="services-upload"
                        :showEdit="false"
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @mark-is-primary="primaryMarked"
                        :popupText="$t('VueUploadMultipleImages.popupText')"
                        :primaryText="$t('VueUploadMultipleImages.primaryText')"
                        :markIsPrimaryText="$t('VueUploadMultipleImages.markIsPrimaryText')"
                        :browseText="$t('VueUploadMultipleImages.browseText')"
                        dragText=""
                        :data-images="images" />
                </div>
                <!-- general information -->
                <div class="vx-col w-full md:mb-2 pl-2 mt-2 required">
                    <vx-tooltip :text="$t('servicesForm.tooltip.name')" >
                        <vs-input :label="$t('servicesForm.name')" v-model="currentService.name" class="w-full"/>
                    </vx-tooltip>
                </div>
                <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                    <vx-tooltip :text="$t('servicesForm.tooltip.description')" >
                        <vs-textarea :label="$t('servicesForm.description')" height="150px" v-model="currentService.description" class="w-full"/>
                    </vx-tooltip>
                </div>

                <div class="vx-col w-full lg:w-1/2 mb-2 pl-4">
                    <vx-tooltip :text="$t('servicesForm.tooltip.pricingType')" >
                        <div class="container mt-2">
                            <div class="vx-row required">
                                <label class="v-select-label">{{$t('servicesForm.choosePricingType')}}</label>
                            </div>
                            <div class="vx-row mt-3">
                                <vs-radio class="mr-6" v-model="currentService.pricing_type" vs-name="currentService.pricing_type" vs-value="fixed">{{$t('home.editBookingOptionForm.selectText.fixed')}}</vs-radio>
                                <vs-radio class="mr-6" v-model="currentService.pricing_type" vs-name="currentService.pricing_type" vs-value="variable">{{$t('home.editBookingOptionForm.selectText.per_person')}}</vs-radio>
                            </div>

                        </div>
                    </vx-tooltip>
                </div>
                <!-- <div class="vx-col  w-1/2 md:mb-2 pl-2 mt-2">
                    <vx-tooltip :text="$t('servicesForm.tooltip.percentageTax')" >
                        <label class="v-select-label">{{$t('servicesForm.percentageTax')}}</label>
                        <vs-checkbox color="warning" class="mt-3" v-model="currentService.percentage_tax">{{$t('servicesForm.enableTax')}}</vs-checkbox>
                    </vx-tooltip>
                </div> -->

                <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="currentService.pricing_type == 'fixed'">
                    <vx-tooltip :text="$t('servicesForm.tooltip.fixedPrice')" >
                        <vs-input type="number" :label="$t('servicesForm.fixedPrice')" v-model="currentService.fixed_price" class="w-full"/>
                    </vx-tooltip>
                </div>

                <vs-tabs v-if="currentService.pricing_type == 'variable'">
                    <vs-tab :label="$t('servicesForm.groupPricing.primaryPrices')">
                        <div class="con-tab-ejemplo">
                            <div class="vx-row mb-5">
                                <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                                    <vx-tooltip :text="$t('servicesForm.tooltip.manPrice')" >
                                        <vs-input type="number" :label="$t('servicesForm.manPrice')" v-model="currentService.first_input_price" class="w-full"/>
                                    </vx-tooltip>
                                </div>

                                <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                                    <vx-tooltip :text="$t('servicesForm.tooltip.womanPrice')" >
                                        <vs-input type="number" :label="$t('servicesForm.womanPrice')" v-model="currentService.second_input_price" class="w-full"/>
                                    </vx-tooltip>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab :label="$t('servicesForm.groupPricing.groupPrices')">
                        <div class="con-tab-ejemplo">
                            <div class="vx-row mb-5">
                                <vs-alert color="warning" icon="sms" :active.sync="active1" closable close-icon="close" class="h-full mb-5 py-2 pr-2">
                                    <h5 class="mb-3">{{$t('servicesForm.groupPricing.alert.header')}}</h5>
                                    <p>{{$t('servicesForm.groupPricing.alert.line1')}}</p>
                                    <p>{{$t('servicesForm.groupPricing.alert.line2')}}</p>
                                </vs-alert>
                            </div>
                            <div class="vx-row h-full">
                                <div class="mt-2 w-full flex justify-between" v-for="(customPricing, index) in currentService.custom_prices" :key="index">
                                    <vs-input type="number" class="pl-2" v-model="customPricing.men_count" :label="$t('servicesForm.groupPricing.menCount')" autocomplete="off"/>
                                    <vs-input type="number" class="pl-1" v-model="customPricing.first_input_price" :label="$t('servicesForm.groupPricing.manPrice')" autocomplete="off"/>
                                    <vs-input type="number" class="pl-1" v-model="customPricing.women_count" :label="$t('servicesForm.groupPricing.womenCount')" autocomplete="off"/>
                                    <vs-input type="number" class="pl-1" v-model="customPricing.second_input_price" :label="$t('servicesForm.groupPricing.womanPrice')" autocomplete="off"/>
                                    <!-- <vs-input type="number" class="pl-1" v-model="customPricing.people_count" label="people_count" autocomplete="off"/> -->
                                    <!-- <vs-input type="number" class="pl-1" v-model="customPricing.third_input_price" label="third_input" autocomplete="off"/> -->
                                    <div class="mt-1 px-2">
                                        <vs-button @click="removeRow(index)" color="danger" icon="delete" class="m-0 mt-5"></vs-button>
                                    </div>
                                </div>
                                <div class="mt-2 w-full flex justify-end">
                                    <vs-button @click="addRow" color="success" icon="add" class="m-0 mt-5 mr-2">{{$t('servicesForm.groupPricing.addRow')}}</vs-button>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
                


                <div class="vx-col w-full pl-4">
                    <div class="container mt-2">
                        <div class="vx-row">
                            <div class="vx-col w-full lg:w-1/2">
                                <div class="vx-row mt-2 required">
                                    <label class="v-select-label">{{$t('home.editBookingOptionForm.taxingStatus')}}</label>
                                </div>
                                <div class="vx-row mt-1">
                                    <vs-radio class="mr-6 mt-2" v-model="currentService.percentage_tax" vs-name="currentService.percentage_tax" :vs-value="1">{{$t('home.editBookingOptionForm.taxable')}}</vs-radio>
                                    <vs-radio class="mr-2 mt-2" v-model="currentService.percentage_tax" vs-name="currentService.percentage_tax" :vs-value="0">{{$t('home.editBookingOptionForm.nonTaxable')}}</vs-radio>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2" v-if="currentService.percentage_tax">
                                <div class="vx-row mt-2 required">
                                    <label class="v-select-label">{{$t('home.editBookingOptionForm.taxingIncluding')}}</label>
                                </div>
                                <div class="vx-row mt-3">
                                    <vs-radio class="mr-6 mt-1 lg:mt-0" v-model="tax_included" vs-name="tax_included" vs-value="included">{{$t('home.editBookingOptionForm.taxIncluded.included')}}</vs-radio>
                                    <vs-radio class="mr-2  mt-2 lg:mt-0" v-model="tax_included" vs-name="tax_included" vs-value="not-included">{{$t('home.editBookingOptionForm.taxIncluded.notIncluded')}}</vs-radio>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="vx-col w-full my-3 opacity-50">
                    <hr>
                </div>
                <div class="vx-col w-full mt-3">
                    <vs-table :data="eventTypesList">
                        <template slot="header">
                            <h3>{{$t('home.editBookingOptionForm.finalPricesTable.header')}}</h3>
                        </template>
                        <template slot="thead">
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.pricing')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.beforeTax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.tax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.totalPrice')}}</vs-th>
                        </template>

                        <template >
                            <vs-tr v-if="currentService.pricing_type == 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.fixedPrice')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                            <vs-tr v-if="currentService.pricing_type != 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.manPrice')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                            <vs-tr v-if="currentService.pricing_type != 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.womanPrice')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                        </template>
                    </vs-table>
                </div>


                <div class="vx-col  w-full md:mb-2 pl-2 mt-2 required">
                  <label class="v-select-label">{{$t('servicesForm.addBookingOptions')}}</label>
                  <v-select
                    multiple
                    class="v-select-background"
                    v-model="selectedBookingOptions"
                    :options="bookingOptionsList"
                    label="name"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div>


                <div class="vx-col w-full md:mb-2 pl-2 mt-4">
                    <vs-button icon-pack="feather" icon="icon-check" @click="submitForm" class="mx-2">{{$t('servicesForm.save')}}</vs-button>
                </div>
            </div>
        </div>
        </vs-popup>
    </div>
</template>

<script>
import axios from '@/axios'

import vSelect from 'vue-select'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import 'swiper/dist/css/swiper.min.css'
export default {
    name: 'add-on-form',
    props: {
        serviceActive: {
            type: Boolean,
            defult: false
        },
        currentService: {
            type: Object,
            defult: null
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        actionType: {
            type: String,
            default: 'addService'
        },
    },
    data () {
        return {
            images: [],
            selectedBookingOptions: [],
            tax_included: 'not-included',
            active1: true,
            finalPrices: {
                fixedPrices: {
                    beforeTax: 0,
                    tax: 0,
                    finalPrice: 0
                },
                variablePrices: {
                    manPrice: {
                        beforeTax: 0,
                        tax: 0,
                        finalPrice: 0
                    },
                    womanPrice: {
                        beforeTax: 0,
                        tax: 0,
                        finalPrice: 0
                    }

                }
            }
        }
    },
    components: {
        vSelect,VueUploadMultipleImage
    },
    computed: {
        isActive: {
            get() {
                return this.serviceActive
            },
            set(newState) {
                return newState
            }
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,

        eventTypesList () {
            return [...this.$store.state.dataList.eventTypesList]
        },
        bookingOptionsList () {
            return this.$store.state.general.bookingOptionsList;
        },
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        }
    },
    created () {
        // this.refactorExtraImages();
        this.$store.dispatch('dataList/fetchEventTypesList')
        this.$store.dispatch('general/getBookingOptionsList', this.currentVenueId)
    },
    methods: {
        submitForm() {
            this.$store.dispatch('loader/loaderOn')

            let currentService = this.currentService;
            const service = new FormData();
            Object.keys(currentService).forEach(function(key){
                if (currentService[key] === null) {
                    delete currentService[key]
                }
            });
            for (var key of Object.keys(currentService)) {
                service.append(key, currentService[key]);
            }
            service.append('custom_prices', JSON.stringify(this.currentService.custom_prices))
            service.append('provider_type', 'App\Venue');
            service.append('provider_id', this.currentVenueId);
            if(this.selectedBookingOptions != null) this.selectedBookingOptions.forEach(bookingOption => service.append('bookingOptions[]', bookingOption.id));
            if(this.images && this.images.length > 0) this.images.forEach(img => service.append('images[]', img.imgUrl ? img.imgUrl : img.path));
            service.append('tax_included', this.tax_included);
            let source = this.isOnBoarding ? 'OnBoarding' : 'Normal';
            service.append('source', source);

            let url = '';
            if(this.actionType == 'duplicateService') {
                service.append('is_duplicate', true);
                service.append('duplicated_service', this.currentService.id);
                
                url = `/venues/${this.currentVenueId}/services`;
                
            } else {
                url = this.isEdit ? `/venues/${this.currentVenueId}/services/${this.currentService.id}/update` : `/venues/${this.currentVenueId}/services`

            }

            axios({method: 'post', url: url, data: service })
            .then((response) => {
                this.closePopup();
                this.$parent.getservicesAPI();
                this.$vs.notify({
                    color:'success',
                    text: response.data.success_message || 'تم رفع طلبكم بنجاح'
                })
            }).catch(error => {
                this.errorsDisplay(error);
            }).finally(() => {
                this.$store.dispatch('loader/loaderOff')
            });
        },

        closePopup() {
            this.imagesFiles = null;
            this.$emit('update:serviceActive', false);
        },

        errorsDisplay(error) {
            console.log(error);
            let errs = error.response.data.errors ? Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key]) : []
            let text = '';
            errs.forEach(err => {
                text += '<li>'+err[0]+'</li>'
            });
            this.$vs.notify({
                color:'danger',
                type: 'error',
                title: 'حدث خطأ ما!',
                text: '<ul>'+text+'</ul>'
            });
        },
        // computePrices() {
        //     if(this.tax_included == 'included') {
        //         // fixed prices
        //         this.finalPrices.fixedPrices.tax = (this.currentService.fixed_price - (this.currentService.fixed_price / 1.15)).toFixed(2)
        //         this.finalPrices.fixedPrices.finalPrice = this.currentService.fixed_price;
        //         this.finalPrices.fixedPrices.beforeTax = (this.finalPrices.fixedPrices.finalPrice / 1.15).toFixed(2);

        //         // variable prices
        //         this.finalPrices.variablePrices.manPrice.tax = (this.currentService.first_input_price - (this.currentService.first_input_price / 1.15)).toFixed(2)
        //         this.finalPrices.variablePrices.manPrice.finalPrice = this.currentService.first_input_price;
        //         this.finalPrices.variablePrices.manPrice.beforeTax = (this.currentService.first_input_price / 1.15).toFixed(2);

        //         this.finalPrices.variablePrices.womanPrice.tax = (this.currentService.second_input_price - (this.currentService.second_input_price / 1.15)).toFixed(2)
        //         this.finalPrices.variablePrices.womanPrice.finalPrice = this.currentService.second_input_price;
        //         this.finalPrices.variablePrices.womanPrice.beforeTax = (this.currentService.second_input_price / 1.15).toFixed(2);
        //     } else {
        //         if(this.currentService.percentage_tax == 1) {
        //             this.taxablePrices();
        //         } else {
        //             this.nonTaxablePrices();
        //         }
        //     }
        // },
        // taxablePrices() {
        //     // fixed prices
        //     this.finalPrices.fixedPrices.tax = ((this.currentService.fixed_price * 1.15) - this.currentService.fixed_price).toFixed(2)
        //     this.finalPrices.fixedPrices.finalPrice = (this.currentService.fixed_price * 1.15).toFixed(2);
        //     this.finalPrices.fixedPrices.beforeTax = this.currentService.fixed_price;

        //     // variable prices
        //     this.finalPrices.variablePrices.manPrice.tax = ((this.currentService.first_input_price * 1.15) - this.currentService.first_input_price).toFixed(2)
        //     this.finalPrices.variablePrices.manPrice.finalPrice = (this.currentService.first_input_price * 1.15).toFixed(2);
        //     this.finalPrices.variablePrices.manPrice.beforeTax = this.currentService.first_input_price;

        //     this.finalPrices.variablePrices.womanPrice.tax = ((this.currentService.second_input_price * 1.15) - this.currentService.second_input_price).toFixed(2)
        //     this.finalPrices.variablePrices.womanPrice.finalPrice = (this.currentService.second_input_price * 1.15).toFixed(2);
        //     this.finalPrices.variablePrices.womanPrice.beforeTax = this.currentService.second_input_price;
        // },
        // nonTaxablePrices() {
        //     // fixed prices
        //     this.finalPrices.fixedPrices.tax = (0).toFixed(2);
        //     this.finalPrices.fixedPrices.finalPrice = Number(this.currentService.fixed_price).toFixed(2);
        //     this.finalPrices.fixedPrices.beforeTax = Number(this.currentService.fixed_price).toFixed(2);

        //     // variable prices
        //     this.finalPrices.variablePrices.manPrice.tax = (0).toFixed(2);
        //     this.finalPrices.variablePrices.manPrice.finalPrice = Number(this.currentService.first_input_price).toFixed(2);
        //     this.finalPrices.variablePrices.manPrice.beforeTax = Number(this.currentService.first_input_price).toFixed(2);

        //     this.finalPrices.variablePrices.womanPrice.tax = (0).toFixed(2);
        //     this.finalPrices.variablePrices.womanPrice.finalPrice = Number(this.currentService.second_input_price).toFixed(2);
        //     this.finalPrices.variablePrices.womanPrice.beforeTax = Number(this.currentService.second_input_price).toFixed(2);
        // },
        computePrices() {
            if(this.tax_included == 'included') {
                // fixed prices
                this.finalPrices.fixedPrices.tax = Math.ceil(this.currentService.fixed_price - (this.currentService.fixed_price / 1.15))
                this.finalPrices.fixedPrices.finalPrice = this.currentService.fixed_price;
                this.finalPrices.fixedPrices.beforeTax = this.currentService.fixed_price - this.finalPrices.fixedPrices.tax;

                // variable prices
                this.finalPrices.variablePrices.manPrice.tax = Math.ceil(this.currentService.first_input_price - (this.currentService.first_input_price / 1.15))
                this.finalPrices.variablePrices.manPrice.finalPrice = this.currentService.first_input_price;
                this.finalPrices.variablePrices.manPrice.beforeTax = this.currentService.first_input_price - this.finalPrices.variablePrices.manPrice.tax

                this.finalPrices.variablePrices.womanPrice.tax = Math.ceil(this.currentService.second_input_price - (this.currentService.second_input_price / 1.15))
                this.finalPrices.variablePrices.womanPrice.finalPrice = this.currentService.second_input_price;
                this.finalPrices.variablePrices.womanPrice.beforeTax = this.currentService.second_input_price - this.finalPrices.variablePrices.womanPrice.tax
            } else {
                if(this.currentService.percentage_tax == 1) {
                    this.taxablePrices();
                } else {
                    this.nonTaxablePrices();
                }
            }
        },
        taxablePrices() {
            // fixed prices
            this.finalPrices.fixedPrices.tax = Math.ceil((this.currentService.fixed_price * 1.15) - this.currentService.fixed_price)
            this.finalPrices.fixedPrices.finalPrice = Number(this.currentService.fixed_price) + Number(this.finalPrices.fixedPrices.tax)
            this.finalPrices.fixedPrices.beforeTax = this.currentService.fixed_price;

            // variable prices
            this.finalPrices.variablePrices.manPrice.tax = Math.ceil((this.currentService.first_input_price * 1.15) - this.currentService.first_input_price)
            this.finalPrices.variablePrices.manPrice.finalPrice = Number(this.currentService.first_input_price) + Number(this.finalPrices.variablePrices.manPrice.tax)
            this.finalPrices.variablePrices.manPrice.beforeTax = this.currentService.first_input_price;

            this.finalPrices.variablePrices.womanPrice.tax = Math.ceil((this.currentService.second_input_price * 1.15) - this.currentService.second_input_price)
            this.finalPrices.variablePrices.womanPrice.finalPrice = Number(this.currentService.second_input_price) + Number(this.finalPrices.variablePrices.womanPrice.tax)
            this.finalPrices.variablePrices.womanPrice.beforeTax = this.currentService.second_input_price;
        },
        nonTaxablePrices() {
            // fixed prices
            this.finalPrices.fixedPrices.tax = (0).toFixed(2);
            this.finalPrices.fixedPrices.finalPrice = Math.ceil(Number(this.currentService.fixed_price));
            this.finalPrices.fixedPrices.beforeTax = Math.ceil(Number(this.currentService.fixed_price));

            // variable prices
            this.finalPrices.variablePrices.manPrice.tax = (0).toFixed(2);
            this.finalPrices.variablePrices.manPrice.finalPrice = Math.ceil(Number(this.currentService.first_input_price));
            this.finalPrices.variablePrices.manPrice.beforeTax = Math.ceil(Number(this.currentService.first_input_price));

            this.finalPrices.variablePrices.womanPrice.tax = (0).toFixed(2);
            this.finalPrices.variablePrices.womanPrice.finalPrice = Math.ceil(Number(this.currentService.second_input_price));
            this.finalPrices.variablePrices.womanPrice.beforeTax = Math.ceil(Number(this.currentService.second_input_price));
        },
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove (index, done, fileList) {
            var r = confirm("remove image")
            if (r == true) {
                done()
            } else {
            }
            this.images = fileList;
        },
        primaryMarked(index, fileList) {
            this.images = fileList;
        }, 
        addRow() {
            this.currentService.custom_prices.push({
                men_count: null,
                first_input_price: null,
                women_count: null,
                second_input_price: null,
                people_count: null,
                third_input_price: null,
            });
        },
        removeRow(index) {
            this.currentService.custom_prices.splice(index, 1)
        }
    },
    watch: {
        currentService(newVal) {
            this.selectedBookingOptions = newVal.booking_options
            this.currentService.percentage_tax = newVal.percentage_tax > 0 ? 1 : 0;
            if(newVal.custom_prices == null) this.currentService.custom_prices = [];
        },
        tax_included(newVal){
            if(newVal == 'included') {
                this.currentService.percentage_tax = 1;
            }
            this.computePrices()
        },
        'currentService.images'(imgs){
            if(imgs) {
                if(imgs.length > 0) this.images = imgs.map((image, index) => { return { path: this.baseURL+'/'+image ,default: index == 0 ? 1 : 0,highlight: index == 0 ? 1 : 0,caption: 'caption to display. receive', imgUrl: image}});
            } else {
                this.images = this.images.length > 0 ? this.images : [];
            }
        },
        'currentService.percentage_tax'(newVal){
            if(newVal == 0) {
                this.tax_included = 'not-included';
            }
            this.computePrices()
        },
        'currentService.fixed_price'(){
            this.computePrices()
        },
        'currentService.first_input_price'(){
            this.computePrices()
        },
        'currentService.second_input_price'(){
            this.computePrices()
        },
    }
}
</script>

<style lang="css">
.edit-flex-col {
    justify-content: flex-end;
}
.vs-tooltip {
  z-index: 99999 !important;
}

input[type=file] {
  padding: 7px 5px;
  border-radius: 8px;
}

input[type=file]::file-selector-button {
  border: none;
  padding: .75rem 2rem;
  border-radius: 6px;
  color: white;
  background-color: rgb(255,177,53);
  transition: 1s;
}

input[type=file]::file-selector-button:hover {
  background-color: rgb(248, 188, 91);
}


</style>
