<template>
    <div>
        <div v-if="service">
            <vs-popup class="holamundo " :title="actionType == 'addService' ? $t('servicesForm.addService') : actionType == 'editService' ? $t('servicesForm.editService') +' | '+ service.name : $t('servicesForm.duplicateService') +' | '+ service.name" :active.sync="isActive" @close="closePopup">
                
                <div class="grid lg:grid-cols-2 gap-5 pt-2">
                    <div class="col-span-2">
                        <vue-upload-multiple-image
                            :idUpload="imagesUploadId"
                            :showEdit="false"
                            @upload-success="uploadImageSuccess"
                            @before-remove="beforeRemove"
                            @mark-is-primary="primaryMarked"
                            :popupText="$t('VueUploadMultipleImages.popupText')"
                            :primaryText="$t('VueUploadMultipleImages.primaryText')"
                            :markIsPrimaryText="$t('VueUploadMultipleImages.markIsPrimaryText')"
                            :browseText="$t('VueUploadMultipleImages.browseText')"
                            dragText=""
                            :maxImage="20"
                            :data-images="images" />
                    </div>

                    <vs-input :label="$t('servicesForm.name')" v-model="service.name" class="w-full required col-span-2" autocomplete="off"/>

                    <vs-textarea :label="$t('servicesForm.description')" height="150px" v-model="service.description" class="w-full col-span-2"/>

                    <div class="col-span-2">
                        <span class="text-md">{{$t('servicesForm.choosePricingType')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-0 space-y-3 sm:space-y-0 sm:space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="service.pricing_type" vs-name="service.pricing_type" vs-value="fixed">{{$t('home.editBookingOptionForm.selectText.fixed')}}</vs-radio>
                            <vs-radio v-model="service.pricing_type" vs-name="service.pricing_type" vs-value="variable">{{$t('home.editBookingOptionForm.selectText.per_person')}}</vs-radio>
                        </div>
                    </div>

                    <div class="col-span-2 grid gap-3" v-if="service.pricing_type == 'fixed'">
                        <vs-input ttype="number" :label="$t('servicesForm.fixedPrice')" v-model="service.fixed_price" class="w-full col-span-2 lg:col-span-1"/>
                    </div>
                    <div class="col-span-2 grid gap-3" v-else> <!-- priceing_type == variable -->
                        <vs-tabs class="w-full">
                            <vs-tab :label="$t('servicesForm.groupPricing.primaryPrices')">
                                <div class="con-tab-ejemplo">
                                    <div class="vx-row mb-5">
                                        <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                                            <vx-tooltip :text="$t('servicesForm.tooltip.manPrice')" >
                                                <vs-input type="number" :label="$t('servicesForm.manPrice')" v-model="service.first_input_price" class="w-full"/>
                                            </vx-tooltip>
                                        </div>

                                        <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                                            <vx-tooltip :text="$t('servicesForm.tooltip.womanPrice')" >
                                                <vs-input type="number" :label="$t('servicesForm.womanPrice')" v-model="service.second_input_price" class="w-full"/>
                                            </vx-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </vs-tab>
                            <vs-tab :label="$t('servicesForm.groupPricing.groupPrices')">
                                <div class="con-tab-ejemplo">
                                    <div class="vx-row mb-5">
                                        <vs-alert color="warning" icon="sms" :active.sync="groupPricesingAlertActive" closable close-icon="close" class="h-full mb-5 py-2 pr-2">
                                            <h5 class="mb-3">{{$t('servicesForm.groupPricing.alert.header')}}</h5>
                                            <p>{{$t('servicesForm.groupPricing.alert.line1')}}</p>
                                            <p>{{$t('servicesForm.groupPricing.alert.line2')}}</p>
                                        </vs-alert>
                                    </div>
                                    <div class="vx-row h-full">
                                        <div class="mt-2 w-full flex justify-between" v-for="(customPricing, index) in service.custom_prices" :key="index">
                                            <vs-input type="number" class="pl-2" v-model="customPricing.men_count" :label="$t('servicesForm.groupPricing.menCount')" autocomplete="off"/>
                                            <vs-input type="number" class="pl-1" v-model="customPricing.first_input_price" :label="$t('servicesForm.groupPricing.manPrice')" autocomplete="off"/>
                                            <vs-input type="number" class="pl-1" v-model="customPricing.women_count" :label="$t('servicesForm.groupPricing.womenCount')" autocomplete="off"/>
                                            <vs-input type="number" class="pl-1" v-model="customPricing.second_input_price" :label="$t('servicesForm.groupPricing.womanPrice')" autocomplete="off"/>
                                            <div class="mt-1 px-2">
                                                <vs-button @click="removeRow(index)" color="danger" icon="delete" class="m-0 mt-5"></vs-button>
                                            </div>
                                        </div>
                                        <div class="mt-2 w-full flex justify-end">
                                            <vs-button @click="addRow" color="success" icon="add" class="m-0 mt-5 mr-2">{{$t('servicesForm.groupPricing.addRow')}}</vs-button>
                                        </div>
                                    </div>
                                </div>
                            </vs-tab>
                        </vs-tabs>
                    </div>

                    <div class="col-span-2 lg:col-span-1">
                        <span class="text-md">{{$t('home.editBookingOptionForm.taxingStatus')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="service.percentage_tax" vs-name="service.percentage_tax" :vs-value="1">{{$t('home.editBookingOptionForm.taxable')}}</vs-radio>
                            <vs-radio v-model="service.percentage_tax" vs-name="service.percentage_tax" :vs-value="0">{{$t('home.editBookingOptionForm.nonTaxable')}}</vs-radio>
                        </div>
                    </div>
                    <div class="col-span-2 lg:col-span-1" v-if="service.percentage_tax">
                        <span class="text-md">{{$t('home.editBookingOptionForm.taxingIncluding')}}</span>
                        <div class="mt-2 flex flex-wrap space-x-3 rtl:space-x-reverse">
                            <vs-radio v-model="tax_included" vs-name="tax_included" vs-value="included">{{$t('home.editBookingOptionForm.taxIncluded.included')}}</vs-radio>
                            <vs-radio v-model="tax_included" vs-name="tax_included" vs-value="not-included">{{$t('home.editBookingOptionForm.taxIncluded.notIncluded')}}</vs-radio>
                        </div>
                    </div>

                    <div class="col-span-2">
                        <span class="text-md">{{$t('servicesForm.addBookingOptions')}}</span>
                        <div class="mt-2">
                            <v-select
                                multiple
                                class="v-select-background"
                                v-model="selectedBookingOptions"
                                :options="bookingOptionsList"
                                label="name"
                                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                            />
                        </div>
                    </div>

                </div>



                <div class="col-span-2 my-10">
                    <div class="flex w-full justify-end">
                        <vs-button icon-pack="feather" icon="icon-check" @click.native="submitForm" class="mx-2">{{$t('save')}}</vs-button>
                    </div>
                </div>

            </vs-popup>
        </div>
    </div>
</template>
  
<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import vSelect from 'vue-select'
export default {
    name: 'service-form-popup',
    components: {
        VueUploadMultipleImage,
        vSelect
    },
    computed: {
        isActive: {
            get() {
                return this.isServiceFormActive
            },
            set(newState) {
                return newState
            }
        },
        imagesUploadId() { // images upload library had some issues with the idUpload so I had to generate a random string for it
            return Math.random().toString(16).substr(2, 32);
        },
        baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        bookingOptionsList () {
            return this.$store.state.general.bookingOptionsList;
        },
    },
    props: {
        isServiceFormActive:  {
            type: Boolean,
            defult: false
        },
        serviceProp: {
            type: Object,
            defult: null
        },
        actionType: { // could be: [addService, duplicateService, editService]
            type: String,
            default: 'addService'
        },
    },
    created() {
        this.$store.dispatch('general/getBookingOptionsList', this.currentVenueId)
    },
    data:()=>({
        images: [],
        selectedBookingOptions: [],
        service: null,

        groupPricesingAlertActive: true,
        tax_included: 'not-included',
    }),
    methods: {
        async submitForm() {
            this.$store.dispatch('loader/loaderOn')
            let data = {
                images: this.images,
                service: this.service,
                selectedBookingOptions: this.selectedBookingOptions,
                source: this.isOnBoarding ? 'OnBoarding' : 'Normal'
            }

            let response = null;
            if(this.actionType == 'editService')
                response = await this.$store.dispatch('services/updateService', {venueId: this.currentVenueId, data});
            else if(this.actionType == 'duplicateService')
                response = await this.$store.dispatch('services/duplicateService', {venueId: this.currentVenueId, data});
            else
                response = await this.$store.dispatch('services/addService', {venueId: this.currentVenueId, data});

            if(response.status == 200) {
                this.closePopup();
                this.$vs.notify({
                    color:'success',
                    text: response.data.message
                })
            } else {
                let errors = response.data.errors;
                Object.keys(errors).forEach(key => {
                    this.$vs.notify({
                        color:'danger',
                        icon: 'warning',
                        text: errors[key][0]
                    })
                });
            }
            this.$store.dispatch('loader/loaderOff')
        },
        closePopup() {
            this.$emit('update:isServiceFormActive', false);
        },
        // IMAGES UPLOADER //
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove (index, done, fileList) {
            var confirmationAlert = confirm("remove image")
            if (confirmationAlert == true) {
                done()
            }
            this.images = fileList;
        },
        primaryMarked(index, fileList) {
            this.images = fileList;
        },
        // ** IMAGES UPLOADER ** //

        // GROUPS PRICINGS //
        addRow() {
            this.service.custom_prices.push({
                men_count: null,
                first_input_price: null,
                women_count: null,
                second_input_price: null,
                people_count: null,
                third_input_price: null,
            });
        },
        removeRow(index) {
            this.service.custom_prices.splice(index, 1)
        }
        //**   GROUPS PRICINGS **/
    },
    watch: {
        isActive(newVal) {
            if(newVal) {
                let service = JSON.parse(JSON.stringify(this.serviceProp))
                
                if(service.images) this.images = service.images.map((image, index) => { return { path: this.baseURL+'/'+image ,default: index == 0 ? 1 : 0,highlight: index == 0 ? 1 : 0,caption: 'caption to display. receive', imgUrl: image}}); else this.images = [];
                if(service.booking_options) this.selectedBookingOptions = service.booking_options
                service.percentage_tax = service.percentage_tax > 0 ? 1 : 0;
                if(service.custom_prices == null) service.custom_prices = [];
                this.service = service;

            }
        },
        'service.percentage_tax'(newVal){
            if(newVal == 0) {
                this.tax_included = 'not-included';
            }
        },
        tax_included(newVal){
            if(newVal == 'included') {
                this.service.percentage_tax = 1;
            }
        },
    }
  }
  </script>
  

  